import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { Box, Flex, Container } from 'pcln-design-system'
import { SilhouetteMarketingModule, MediaCardCollection } from '@pcln/marketing'
import { ActionCard, SpotlightCollection } from '@pcln/cards'
import { Bag, Help } from 'pcln-icons'
import HotelsTonight from '@/components/HotelsTonight/HotelsTonight'
import MarketingTout from '@/components/Promos/MarketingTout/MarketingTout'
import { SearchFormMobile } from '@/components/SearchForm'
import { trackMyTrips, trackHelpCenter } from '@/components/AppDownloadOptIn'
import useBootstrapData from '@/hooks/useBootstrapData'
import mapSilhouetteContentfulData from '@/shared-utils/map-silhouette-contentful-data'
import type { ProductType } from '@/types'
import analytics from '@/shared-utils/analytics'
import { ANALYTICS_CATEGORY_HOME } from '@/constants/analytics'
import pclnExperimentation from '@pcln/experimentation'
import TravelChatBot from '@/components/TravelChatBot'
import LinksWidget from '@/components/SkyMondeSeo/LinksWidget'
import getCurrentTab from '@/shared-utils/getCurrentTab'
import useContentfulVariation from '@/hooks/useContentfulVariation'
import {
  isCollectionOfIMediaCard,
  isISilhouetteMarketingModuleType,
  isISpotlightCollection
} from '@/shared-utils/content-model-type-guards'
import useSeti from '@/hooks/useSeti'
import useNewMobileTab from '@/hooks/useNewMobileTab'
import ContentfulOrderWrapper from '@/components/ContentfulOrderWrapper/ContentfulOrderWrapper'
import TripDisplayOrder from './HomeContent/TripDisplayOrder'
import LayoutComponentWrapper from '../LayoutComponentWrapper'
import MediaCardComponent from '../Promos/MediaCard/MediaCardComponent'
import ConditionalRender, { Original, Experiment } from '../ConditionalRender'
import SearchFormExperimentWrapper from '../SearchFormExperiment/SearchFormExperimentWrapper'

export default function HomeMobile() {
  const { push, query, pathname } = useRouter()
  const showNewMobileTabs = useNewMobileTab()

  const currentTab = getCurrentTab(query)
  const {
    signInStatus: { signedIn },
    skymondeSEO
  } = useBootstrapData()
  const { openPenny, askPrompt } = query

  const openPennyProps = {
    openPenny: typeof openPenny === 'string' ? openPenny : '',
    askPrompt: typeof askPrompt === 'string' ? askPrompt : ''
  }

  const silhouetteContentfulData = useContentfulVariation(
    'silhouetteMarketingModule',
    isISilhouetteMarketingModuleType
  )
  const mappedSilhouetteData = silhouetteContentfulData
    ? mapSilhouetteContentfulData(
        silhouetteContentfulData,
        ANALYTICS_CATEGORY_HOME
      )
    : null

  const spotlightCollectionContentfulData = useContentfulVariation(
    'spotlightCollection',
    isISpotlightCollection
  )
  const mediaCardCollectionData = useContentfulVariation(
    'mediaCardPromoCollection',
    isCollectionOfIMediaCard
  )
  const isMediaCardSetiActive =
    useSeti(
      'HP_BOTTOM_MEDIACARDS',
      Boolean(mediaCardCollectionData?.items?.length)
    ) === 'VARIANT' && mediaCardCollectionData?.items?.length

  useEffect(() => {
    pclnExperimentation.fireImpression('MKTG_MWEB_HOMEPAGE_APP_OVERLAY')
    pclnExperimentation.fireImpression('MKTG_HP_DIGIOH_EMAIL_SMS_SUBSCRIPTION')
  }, [])

  useEffect(() => {
    const spotlightGA4params =
      spotlightCollectionContentfulData?.analyticsTracking?.parameters
    if (spotlightGA4params) {
      analytics.fireGA4Event(spotlightGA4params)
    }
  }, [spotlightCollectionContentfulData])

  const onFormChange = (productType: ProductType) => {
    void push(
      {
        pathname,
        query: {
          ...query,
          tab: productType
        }
      },
      undefined,
      { shallow: true }
    )
    analytics.fireEvent({
      action: 'Tab',
      label: productType,
      category: ANALYTICS_CATEGORY_HOME
    })
  }

  return (
    <Container>
      <ConditionalRender experimentCode="HP_NEW_SEARCH_FORM_2025">
        <Original>
          <SearchFormMobile
            currentTab={currentTab}
            onFormChange={onFormChange}
            showNewMobileTabs={showNewMobileTabs}
          />
        </Original>
        <Experiment>
          <SearchFormExperimentWrapper
            currentTab={currentTab}
            onFormChange={onFormChange}
          />
        </Experiment>
      </ConditionalRender>

      <Flex flexDirection="column" style={{ gap: '48px' }} m={3} mt={4}>
        {spotlightCollectionContentfulData && (
          <SpotlightCollection
            spotlightData={spotlightCollectionContentfulData}
            onClickCallToAction={() => {
              const ga4Parameters =
                spotlightCollectionContentfulData?.callToAction
                  ?.analyticsTracking?.parameters
              if (ga4Parameters) {
                analytics.fireGA4Event(ga4Parameters)
              }
              window.open(spotlightCollectionContentfulData?.callToAction?.url)
            }}
          />
        )}
        <TripDisplayOrder />
        <ContentfulOrderWrapper signedIn={signedIn} isMobile />
      </Flex>
      <LayoutComponentWrapper topGap>
        <Box mx={3}>
          <MarketingTout />
        </Box>
        {mappedSilhouetteData && (
          <SilhouetteMarketingModule {...mappedSilhouetteData} />
        )}
      </LayoutComponentWrapper>
      {isMediaCardSetiActive ? (
        <MediaCardCollection
          collection={mediaCardCollectionData}
          cardNode={MediaCardComponent}
          isCarousel
        />
      ) : null}
      <LayoutComponentWrapper>
        <Box mx={3}>
          <HotelsTonight />
          <Box textAlign="center" mt={4} width={1}>
            <div id="div-gpt-ad-1544641976893-0" />
          </Box>
        </Box>
        <Flex mx={3} mb={4} flexDirection="column" style={{ gap: 16 }}>
          <ActionCard
            headingWidth="200px"
            icon={<Bag color="primary" />}
            heading="My Trips"
            caption="Quickly find your booking reservations and redeem flight credits"
            isIconRounded={false}
            onClick={() => trackMyTrips(signedIn)}
            handleSubmitCTA={() => trackMyTrips(signedIn)}
          />
          <ActionCard
            headingWidth="200px"
            icon={<Help />}
            heading="Help Center"
            isIconRounded={false}
            caption="We are always Here for you - reach us 24hrs a day, 7 days a week"
            onClick={trackHelpCenter}
            handleSubmitCTA={trackHelpCenter}
          />
        </Flex>
        {skymondeSEO ? <LinksWidget isMobile data={skymondeSEO} /> : null}
      </LayoutComponentWrapper>
      <TravelChatBot
        pageName="homepage"
        enableChatBot={currentTab === 'hotels'}
        {...openPennyProps}
      />
    </Container>
  )
}
